import { NoteState } from "@/enums/notes";
import type { DealNotesOptions } from "@/models/common";
import type { ModalOptions } from "@/models/emails";
import { CALL_DIALOG } from "@/helpers/constants/resizable";

export const EXTRA_DEAL_VIEW_REQUESTS = [
  "notes",
  "businessNotes",
  "offers"
] as const;

export const DEAL_MULTISELECT_CONTROLS = [
  "underwriter_full_name",
  "advisor_full_name",
  "analyst_full_name",
  "deal_mode"
] as const;

export const DATA_OPTIONS: Record<
  string,
  (typeof DEAL_MULTISELECT_CONTROLS)[number]
> = {
  DEAL_MODE: "deal_mode",
  ADVISOR: "advisor_full_name",
  ANALYST: "analyst_full_name",
  UNDERWRITER: "underwriter_full_name"
};

//role related smartview filter options come with 2 static options: none and current user (these are set on the FE)
export const NUMBER_OF_STATIC_ROLE_OPTIONS = 2 as const;

export const DEAL_SERVICE_TYPES = {
  regular: "regular",
  premier: "premier",
  pdf: "pdf"
} as const;

export const DEFAULT_DEAL_NOTES_OPTIONS: DealNotesOptions = {
  state: NoteState.Closed,
  minimized: false,
  x: 0,
  y: 0,
  width: 400,
  height: 600,
  isPiPModeEnabled: false
};

export const DEFAULT_EMAIL_MODAL_OPTIONS: ModalOptions = {
  state: NoteState.Draggable,
  minimized: false,
  top: 0,
  left: 0,
  width: 650,
  height: 600,
  restoreWidth: 0,
  restoreHeight: 0
} as const;

export const DEFAULT_SMS_MODAL_OPTIONS: ModalOptions = {
  state: NoteState.Draggable,
  minimized: false,
  top: 0,
  left: 0,
  width: 650,
  height: 400,
  restoreWidth: 0,
  restoreHeight: 0
} as const;

export const DEFAULT_CALL_MODAL_OPTIONS: ModalOptions = {
  state: NoteState.Draggable,
  minimized: false,
  top: CALL_DIALOG.top,
  left: CALL_DIALOG.left,
  width: CALL_DIALOG.width,
  height: CALL_DIALOG.height,
  restoreWidth: CALL_DIALOG.restoreWidth,
  restoreHeight: CALL_DIALOG.restoreHeight,
  isPiPModeEnabled: false
} as const;

export const DEFAULT_TASK_MODAL_OPTIONS: ModalOptions = {
  state: NoteState.Draggable,
  minimized: false,
  top: 0,
  left: 0,
  width: 500,
  height: 350,
  restoreWidth: 0,
  restoreHeight: 0
} as const;

export const FILE_TYPES_WITH_OWNERS = ["signed_application_doc"];

export const FILE_TYPE_OTHER = "other";

export const LINE_TYPE_NOT_DEFINED = "not_defined";
