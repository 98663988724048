<template>
  <lf-modal
    :title="
      isNew
        ? $t('DEALS.DEAL_PROGRESS.PERSONAL_INFO.ADD_NEW_OWNER')
        : $t('DEALS.DEAL_PROGRESS.PERSONAL_INFO.EDIT_PERSONAL_INFO', {
            name: ownerFullName
          })
    "
    :close="close"
  >
    <template v-slot:content>
      <form :novalidate="true" @submit.prevent="onSubmit">
        <div class="p-6">
          <lf-h3 class="mb-6">
            {{ $t("DEALS.DEAL_PROGRESS.PERSONAL_INFO.TITLE") }}
          </lf-h3>
          <div class="flex flex-row flex-wrap space-x-0 sm:space-x-5">
            <lf-input
              v-if="isFieldVisible(PERSONAL_INFO_FIELD_IDS.first_name)"
              :placeholder="$t('COMMON.FIRST_NAME')"
              :value="personal.personal_first_name"
              :name="PERSONAL_INFO_FIELD_IDS.first_name"
            />
            <lf-input
              v-if="isFieldVisible(PERSONAL_INFO_FIELD_IDS.last_name)"
              :placeholder="$t('COMMON.LAST_NAME')"
              :value="personal.personal_last_name"
              :name="PERSONAL_INFO_FIELD_IDS.last_name"
            />
          </div>
          <div class="flex flex-row flex-wrap space-x-0 sm:space-x-5">
            <lf-input
              v-if="
                isFieldVisible(PERSONAL_INFO_FIELD_IDS.drivers_license_number)
              "
              :value="personal.personal_drivers_license_number"
              :name="PERSONAL_INFO_FIELD_IDS.drivers_license_number"
              :placeholder="
                $t('DEALS.DEAL_PROGRESS.PERSONAL_INFO.DRIVERS_LICENSE_NUMBER')
              "
            />
            <lf-dropdown
              v-if="
                isFieldVisible(PERSONAL_INFO_FIELD_IDS.drivers_license_State)
              "
              :value="personal.personal_drivers_license_state"
              :name="PERSONAL_INFO_FIELD_IDS.drivers_license_State"
              :placeholder="
                $t('DEALS.DEAL_PROGRESS.PERSONAL_INFO.DRIVERS_LICENSE_STATE')
              "
              :options="options.states"
              full-width
              search-enabled
            />
          </div>
          <div class="flex flex-row flex-wrap space-x-0 sm:space-x-5">
            <lf-input
              v-if="isFieldVisible(PERSONAL_INFO_FIELD_IDS.birth_date)"
              :value="personal?.personal_date_of_birth"
              :name="PERSONAL_INFO_FIELD_IDS.birth_date"
              date-format="mm/dd/yyyy"
              :placeholder="$t('COMMON.BIRTHDAY')"
              type="date"
            />
          </div>
          <div class="w-1/2">
            <multiple-inputs-with-primary-value
              v-if="isFieldVisible(PERSONAL_INFO_FIELD_IDS.email)"
              type="email"
              :all-values="allEmails"
              :placeholder="$t('DEALS.DEAL_PROGRESS.PERSONAL_INFO.EMAIL')"
              :name="PERSONAL_INFO_FIELD_IDS.email"
              :error-messages="emailErrors"
              :add-more-text="$t('COMMON.ADD_EMAIL')"
              @primary-changed="(value) => (primaryEmail = value)"
            />
          </div>
          <div
            class="flex flex-row flex-wrap items-start justify-start space-x-0 sm:space-x-5"
          >
            <multiple-inputs-with-primary-value
              v-if="isFieldVisible(PERSONAL_INFO_FIELD_IDS.telephone)"
              :all-values="allPhones"
              :placeholder="$t('DEALS.DEAL_PROGRESS.PERSONAL_INFO.PHONE')"
              :name="PERSONAL_INFO_FIELD_IDS.phone_numbers"
              :error-messages="telephoneErrors"
              :add-more-text="$t('COMMON.ADD_PHONE')"
              is-wfb-object
              @primary-changed="(value) => (primaryPhone = value)"
              @remove-phone="removePhone"
            >
              <template #supplemental="{ phone }">
                <lf-dropdown
                  :name="`line_type_${phone.id}`"
                  class="w-1/2 flex-0 mb-5"
                  :options="phoneLineTypeOptions"
                  :value="phone?.line_type || LINE_TYPE_NOT_DEFINED"
                  :placeholder="$t('DEALS.NUMVERIFY.LINE_TYPE')"
                  full-width
                />
              </template>
            </multiple-inputs-with-primary-value>
          </div>
          <div
            v-if="isFieldVisible(PERSONAL_INFO_FIELD_IDS.ownership_percentage)"
            class="flex flex-row flex-wrap space-x-0 sm:space-x-5 mb-6"
          >
            <div
              class="flex items-center justify-between px-6 py-4 rounded w-full bg-gray-50 mt-3 relative"
            >
              <div class="flex items-center">
                <icon-base
                  :icon="IconIndustryNoBg"
                  class="mr-6"
                  icon-color="lightgray"
                />
                <div>
                  <lf-h4>
                    {{
                      $t("DEALS.DEAL_PROGRESS.PERSONAL_INFO.COMPANY_OWNERSHIP")
                    }}
                  </lf-h4>
                  <p>
                    {{
                      $t("DEALS.DEAL_PROGRESS.PERSONAL_INFO.SELECT_PERCENTAGE")
                    }}
                  </p>
                </div>
              </div>
              <span
                class="flex items-center font-medium text-2xl text-black cursor-pointer select-none"
                @click="isOwnershipMenuOpen = !isOwnershipMenuOpen"
              >
                {{ ownershipPercentage }}
                <icon-base
                  :icon="IconArrowDown"
                  height="10"
                  width="10"
                  class="transform ml-2"
                  :class="{
                    'rotate-0': isOwnershipMenuOpen,
                    'rotate-180': !isOwnershipMenuOpen
                  }"
                />
              </span>
              <div
                class="top-0 right-0 z-50 overflow-y-auto max-h-100 transform translate-y-15 shadow-lg border rounded p-4 pt-6 pb-0 bg-white"
                :class="{
                  hidden: !isOwnershipMenuOpen,
                  absolute: isOwnershipMenuOpen
                }"
              >
                <lf-input
                  :value="personal.personal_ownership_percentage"
                  :name="PERSONAL_INFO_FIELD_IDS.ownership_percentage"
                  :placeholder="
                    $t('DEALS.DEAL_PROGRESS.PERSONAL_INFO.OWNERSHIP_PERCENTAGE')
                  "
                  type="number"
                  class="w-80 p-4 border border-blue-500 rounded"
                  @key-released="updateMainOwnershipPercentage"
                />
                <lf-input
                  v-for="(owner, index) in otherOwners"
                  :key="index"
                  :value="owner.personal_ownership_percentage"
                  :name="`ownership_percentage_${owner.personal_information_id}`"
                  :placeholder="`${owner.personal_first_name} ${owner.personal_last_name}`"
                  type="number"
                  class="w-80"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-row flex-wrap space-x-0 sm:space-x-5">
            <lf-input
              v-if="isFieldVisible(PERSONAL_INFO_FIELD_IDS.ssn_itin)"
              :value="ssnOrSin"
              name="personal_ssn_itin.ssn"
              :placeholder="$t('DEALS.DEAL_PROGRESS.PERSONAL_INFO.SSN')"
            />
            <lf-input
              v-if="isFieldVisible(PERSONAL_INFO_FIELD_IDS.ssn_itin)"
              :value="itin"
              name="personal_ssn_itin.itin"
              :placeholder="$t('DEALS.DEAL_PROGRESS.PERSONAL_INFO.ITIN')"
            />
          </div>
          <div v-if="isFieldVisible(PERSONAL_INFO_FIELD_IDS.sin)">
            <lf-input
              :value="personal?.personal_sin"
              :name="PERSONAL_INFO_FIELD_IDS.sin"
              :placeholder="$t('DEALS.DEAL_PROGRESS.PERSONAL_INFO.SIN')"
            />
          </div>
          <template v-if="isFieldVisible(PERSONAL_INFO_FIELD_IDS.address)">
            <lf-h3 class="mb-6">
              {{ $t("DEALS.DEAL_PROGRESS.PERSONAL_INFO.HOME_ADDRESS") }}
            </lf-h3>
            <multiple-addresses v-model:addresses="allAddresses" />
          </template>
          <div class="border rounded p-6 flex items-center">
            <lf-switch
              :disabled="isPrimaryOwner"
              :model-value="isPrimary"
              name="personal_is_primary"
              class="mr-6 flex-none"
              @toggle-changed="setIsPrimary"
            />
            <div>
              <lf-h4>
                {{
                  $t("DEALS.DEAL_PROGRESS.PERSONAL_INFO.SET_PRIMARY_CONTACT")
                }}
              </lf-h4>
              <p>
                {{
                  $t(
                    "DEALS.DEAL_PROGRESS.PERSONAL_INFO.PRIMARY_CONTACT_DESCRIPTION"
                  )
                }}: {{ primaryOwner?.personal_first_name }}
                {{ primaryOwner?.personal_last_name }}.
              </p>
            </div>
          </div>
        </div>
        <div class="border-t border-gray-200 p-6 flex justify-end">
          <outline-button class="mr-3" @click.prevent="close">
            {{ $t("COMMON.CANCEL") }}
          </outline-button>
          <primary-button type="submit" :disabled="isSubmitting">
            {{ $t("COMMON.SAVE") }}
          </primary-button>
        </div>
      </form>
    </template>
  </lf-modal>
</template>
<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";

import type { PropType } from "vue";
import { computed, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import { dispatchAction } from "@/helpers/vee-validate";

import type { IAddress } from "@/models/common";
import type { IRootState } from "@/models/state";
import type { IOptions } from "@/models/options";
import { formatPercentage } from "@/helpers/formatting";
import { useWorkflowFields } from "@/hooks/workflow";
import {
  PERSONAL_INFO_FIELD_IDS,
  WORKFLOW_BLOCKS_IDS
} from "@/helpers/constants/workflow";
import type { DeleteRecord, OwnerWFL, PhoneNumber } from "@/models/workflows";
import { useDeals } from "@/hooks/deals";
import { deletePiiMaskedFields } from "@/helpers/workflow";
import { filterOutEmptyAddresses } from "@/helpers/deals";
import { LINE_TYPE_NOT_DEFINED } from "@/helpers/constants/deals";
import last from "lodash/last";

import MultipleAddresses from "@/views/deals/components/MultipleAddresses.vue";
import MultipleInputsWithPrimaryValue from "@/views/deals/components/MultipleInputsWithPrimaryValue.vue";
import IconArrowDown from "@/components/icons/IconDownArrow.vue";
import IconIndustryNoBg from "@/components/icons/IconIndustryNoBg.vue";

const props = defineProps({
  personal: {
    type: Object as PropType<OwnerWFL>,
    default: () => ({
      personal_first_name: "",
      personal_last_name: "",
      personal_drivers_license_number: null,
      personal_drivers_license_state: null,
      personal_date_of_birth: null,
      personal_telephone: {
        telephone: "",
        additional_telephones: []
      },
      personal_phone_numbers: [],
      personal_email_address: {
        email_address: "",
        additional_emails: []
      },
      personal_ssn_itin: {
        ssn: "",
        itin: ""
      },
      personal_sin: "",
      personal_addresses: [],
      personal_is_primary: false,
      personal_ownership_percentage: 0,
      personal_information_id: null
    })
  },
  languages: {
    type: Object as PropType<Record<string, string>[]>,
    required: false
  },
  owners: {
    type: Array as PropType<OwnerWFL[]>,
    required: true
  },
  close: {
    type: Function as PropType<() => void>,
    required: true
  },
  isNew: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits<{
  "update-data": [];
  error: [];
}>();

const emailErrors = ref<string[]>([]);
const telephoneErrors = ref<string[]>([]);
const telephoneToDelete = ref<DeleteRecord[]>([]);

const { getters, dispatch, commit } = useStore<IRootState>();

const { isFieldVisible } = useWorkflowFields(WORKFLOW_BLOCKS_IDS.personal_info);
const { activeDeal } = useDeals();

const isOwnershipMenuOpen = ref(false);

const { personal: personalInfo, isNew } = toRefs(props);
const primaryEmail = ref(
  personalInfo.value?.personal_email_address?.email_address || ""
);
const primaryPhone = ref(
  personalInfo.value?.personal_phone_numbers?.find((phone) => phone.is_primary)
    ?.id || ""
);

const allEmails = ref(
  [
    personalInfo.value?.personal_email_address?.email_address,
    ...(personalInfo.value?.personal_email_address?.additional_emails || [])
  ].filter(Boolean) as string[]
);

const allPhones = ref(personalInfo.value?.personal_phone_numbers || []);

const allAddresses = ref(
  [...((personalInfo.value?.personal_addresses || []) as IAddress[])].filter(
    Boolean
  )
);

const primaryOwner = computed(() =>
  props.owners.find((owner) => owner.personal_is_primary)
);

const options = computed<IOptions>(() => getters["options/all"]);
const phoneLineTypeOptions = computed(() => options.value?.line_types || {});

const ssnOrSin = computed(() => props.personal?.personal_ssn_itin?.ssn);

const itin = computed(() => props.personal?.personal_ssn_itin?.itin);

const isPrimaryOwner = computed(
  () =>
    props.personal?.personal_information_id ===
    primaryOwner.value?.personal_information_id
);

const personalDataIsPrimaryOwner = computed(
  () => props.personal?.personal_is_primary
);
const isPrimary = ref<boolean>(personalDataIsPrimaryOwner.value || false);

const otherOwners = computed(() =>
  props.owners?.filter(
    (owner) =>
      owner?.personal_information_id !== props.personal?.personal_information_id
  )
);

const ownerFullName = computed(() => {
  return props.personal
    ? `${props.personal?.personal_first_name} ${props.personal?.personal_last_name}`
    : "";
});

const mainOwnershipPercentage = ref(
  props.personal?.personal_ownership_percentage
);

const ownershipPercentage = computed(
  () => formatPercentage(Number(mainOwnershipPercentage.value)) || ""
);

const updateMainOwnershipPercentage = (value: string) => {
  mainOwnershipPercentage.value = value;
};

const setIsPrimary = (value: boolean) => (isPrimary.value = value);

const getOwner = (id: number) =>
  props?.owners.filter((owner) => owner?.personal_information_id === id)?.[0];

const updateOtherOwners = async (values: Partial<OwnerWFL>) => {
  const ownerships = Object.keys(values).filter((value) =>
    value.startsWith("ownership_percentage_")
  );

  const ownerIds = ownerships.map((value) => Number(value.split("_").pop()));

  const otherOwners = ownerIds.reduce<
    { personal_information_id: number; personal_ownership_percentage: number }[]
  >((result, id) => {
    const ownershipPercentageId =
      `ownership_percentage_${id}` as unknown as keyof OwnerWFL;
    if (
      getOwner(id)?.personal_ownership_percentage !==
      values?.[ownershipPercentageId]
    ) {
      result.push({
        personal_information_id: id,
        personal_ownership_percentage: Number(values?.[ownershipPercentageId])
      });
    }
    return result;
  }, []);
  if (!otherOwners.length) {
    return;
  }
  try {
    commit("setPreventGlobalMessage", true);
    await dispatch("workflows/updateWorkflowSnapshotFields", {
      [WORKFLOW_BLOCKS_IDS.personal_info]: otherOwners
    });
  } finally {
    commit("setPreventGlobalMessage", false);
  }
};

const getFormattedEmailsAndPhones = (
  values: Record<string, string | unknown>
) => {
  const additional_emails: string[] = [];
  const phone_numbers: PhoneNumber[] = [];

  Object.keys(values).forEach((key) => {
    if (key.includes("email_address_")) {
      if (primaryEmail.value === key.replace("personal_email_address_", "")) {
        values.email_address = values[key as keyof typeof values];
        delete values[key];
      } else {
        additional_emails.push(values[key] as string);
        delete values[key];
      }
    }
    if (key.includes("personal_phone_numbers_")) {
      // changes here to accommodate BE updates around phone_numbers object
      // instead of telephone and additional_telephones
      phone_numbers.push({
        id: key.replace("personal_phone_numbers_", "") as string,
        phone_number: values[key] as string,
        line_type: values[
          `line_type_${key.replace("personal_phone_numbers_", "")}`
        ] as string,
        is_primary:
          primaryPhone.value === key.replace("personal_phone_numbers_", "")
      });
      delete values[key];
      delete values[`line_type_${key.replace("personal_phone_numbers_", "")}`];
    }
  });

  values.additional_emails = additional_emails;
  values.personal_phone_numbers = [
    ...phone_numbers,
    ...telephoneToDelete.value
  ];
};

const removePhone = (id: number) => {
  const phone = last(
    props.personal?.personal_phone_numbers?.filter(
      (phone) => Number(phone.id) === Number(id)
    )
  );
  telephoneToDelete.value.push({
    id: phone?.id || "",
    _delete: true
  });
};

const { handleSubmit, isSubmitting } = useForm({
  initialValues: {
    ...props.personal,
    email_address: "",
    additional_emails: [],
    telephone: "",
    additional_telephones: [],
    personal_phone_numbers: []
  }
});

const onSubmit = handleSubmit(async (values, actions) => {
  emailErrors.value = [];
  telephoneErrors.value = [];

  getFormattedEmailsAndPhones(values);

  if (values?.personal_ssn_itin) {
    values.personal_ssn_itin = deletePiiMaskedFields(values.personal_ssn_itin, [
      "ssn",
      "itin"
    ]);
  }

  if (values?.personal_sin) {
    values = deletePiiMaskedFields(values, ["personal_sin"]);
  }

  if (values?.personal_ownership_percentage === "") {
    values.personal_ownership_percentage = String(
      values.personal_ownership_percentage
    );
  }

  if (props.personal?.personal_information_id) {
    values.personal_information_id = props.personal.personal_information_id;
  }

  await updateOtherOwners(values);

  Object.keys(values).forEach((value) => {
    if (value.startsWith("ownership_percentage_")) {
      delete values[value as keyof typeof values];
    }
  });

  const payload = {
    ...values,
    personal_email_address: {
      email_address: values.email_address,
      additional_emails: values.additional_emails
    },
    personal_telephone: {
      telephone: values.telephone,
      additional_telephones: values.additional_telephones
    },
    personal_addresses: filterOutEmptyAddresses(allAddresses.value),
    personal_is_primary: isPrimary.value
  };

  try {
    await dispatchAction(
      { [WORKFLOW_BLOCKS_IDS.personal_info]: [payload] },
      actions,
      "workflows/updateWorkflowSnapshotFields"
    );
    dispatch("applications/getApplication", activeDeal.value.id);
  } catch (error) {
    const formattedErrors =
      (error as { formattedErrors: Record<string, string> }).formattedErrors ||
      {};

    // if ownership_percentage input field has an error we need to open the menu showing it
    if (formattedErrors.ownership_percentage) {
      isOwnershipMenuOpen.value = true;
    }

    Object.keys(formattedErrors).forEach((key) => {
      if (key.includes("email")) {
        emailErrors.value.push(formattedErrors[key]);
      }
      if (key.includes("phone_numbers")) {
        telephoneErrors.value.push(formattedErrors[key]);
      }
    });

    return;
  }

  emit("update-data");
  props.close();
});

watch(personalDataIsPrimaryOwner, (value) => {
  isPrimary.value = value;
});

watch(
  () => props.personal?.personal_ownership_percentage,
  (value) => (mainOwnershipPercentage.value = value)
);
</script>
