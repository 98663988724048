<template>
  <lf-card class="py-6 mb-8" data-cy="individual-info-panel">
    <template #header>
      <div class="flex flex-col w-full">
        <div class="flex items-center justify-between">
          <div class="flex items-center self-start">
            <lf-h3>
              {{ $t("INDIVIDUAL_PROFILES.OWNER_INFORMATION") }}
            </lf-h3>
            <div
              class="flex items-center justify-center ml-2 mt-0-5 w-5 h-5 bg-blue-100 rounded-full"
            >
              <span class="-mt-0-25 text-blue-500 font-medium">
                {{ numOfOwners }}
              </span>
            </div>
          </div>
          <div v-if="canEditPersonalInfo">
            <personal-info-modal
              v-if="isPersonalInfoModalOpen"
              :languages="languages"
              :personal="addNewOwner"
              :owners="owners"
              :close="closeModal"
              :is-new="!addNewOwner"
              @update-data="updateSelectedOwner"
            />
            <more-button>
              <ul>
                <li v-if="currentOwner" @click="handleOpenModal(currentOwner)">
                  {{ $t("COMMON.EDIT") }} {{ currentOwnerFullName }}
                </li>
                <li @click="handleOpenModal(undefined)">
                  {{ $t("DEALS.DEAL_PROGRESS.PERSONAL_INFO.ADD_NEW_OWNER") }}
                </li>
                <li
                  v-if="canDeleteOwner && currentOwner"
                  @click="showDeleteModal = true"
                  class="text-red-500"
                >
                  {{ $t("COMMON.DELETE") }} {{ currentOwnerFullName }}
                </li>
              </ul>
            </more-button>
          </div>
        </div>
        <div class="flex flex-wrap gap-1">
          <select-owner-card
            v-for="(owner, index) in owners"
            :key="owner.personal_information_id"
            :owner="owner"
            :index="index"
            :is-selected="isSelected(owner.personal_information_id)"
            is-personal-info
            @click="setOwner(owner.personal_information_id)"
          />
        </div>
      </div>
    </template>
    <loader :isLoading="isLoading" />
    <personal-info-card :personal="currentOwner" :readonly="readonly" />
  </lf-card>
  <confirm-modal
    v-if="showDeleteModal"
    :title="$t('DEALS.DEAL_PROGRESS.PERSONAL_INFO.DELETE_OWNER_TITLE')"
    :description="$t('DEALS.DEAL_PROGRESS.PERSONAL_INFO.DELETE_OWNER_BODY')"
    @confirm="handleDelete"
    :confirm-button="$t('COMMON.DELETE')"
    confirm-button-type="error"
    :close="() => (showDeleteModal = false)"
  />
</template>

<script setup lang="ts">
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useDropdownMenu } from "@/hooks/menus";
import { useDeals } from "@/hooks/deals";
import { useAuth, useDataCollectionCanEdit } from "@/hooks/auth";
import { useWorkflowFields } from "@/hooks/workflow";
import { WORKFLOW_BLOCKS_IDS } from "@/helpers/constants/workflow";
import { sortOwnersArray } from "@/helpers/workflow";

import PersonalInfoCard from "./PersonalInfoCard.vue";
import PersonalInfoModal from "../modals/PersonalInfoModal.vue";
import SelectOwnerCard from "./SelectOwnerCard.vue";
import MoreButton from "./MoreButton.vue";

import type { OwnerWFL } from "@/models/workflows";

const props = defineProps({
  readonly: {
    type: Boolean,
    default: false
  }
});

const { getters, commit, dispatch } = useStore();
const { canEditDealProgress } = useDeals();
const { isFunder, isClient } = useAuth();
const { fieldData } = useWorkflowFields(WORKFLOW_BLOCKS_IDS.personal_info);
const canDeleteOwner = useDataCollectionCanEdit();
const addNewOwner = ref<OwnerWFL | undefined>(undefined);
const isPersonalInfoModalOpen = ref(false);
const selectedId = ref<OwnerWFL["personal_information_id"] | null>(
  fieldData.value?.[0]?.personal_information_id || null
);
const existingData = ref<OwnerWFL["personal_information_id"][]>([]);

commit("workflows/setActiveOwner", selectedId.value);

const currentOwner = computed<OwnerWFL | null>(
  () =>
    fieldData.value?.find(
      ({ personal_information_id }) =>
        personal_information_id === selectedId.value
    ) || null
);
const showDeleteModal = ref(false);

const numOfOwners = computed(() => owners.value.length);
const isLoading = computed<boolean>(() => getters["applications/loading"]);
const languages = computed<Record<string, string>[]>(
  () => getters["options/languages"]
);

const canEditPersonalInfo = computed(
  () => !props.readonly && (!isFunder || isClient) && canEditDealProgress.value
);

const { closeMenu } = useDropdownMenu("dealsWrapper", "fullAppView");

const owners = computed(() => sortOwnersArray(fieldData.value || []));

const currentOwnerFullName = computed(() => {
  if (!currentOwner.value) {
    return;
  }
  const firstName = currentOwner.value.personal_first_name || "";
  const lastName = currentOwner.value.personal_last_name || "";
  return `${firstName} ${lastName}`;
});

const setOwner = (id: OwnerWFL["personal_information_id"]) => {
  selectedId.value = id;
  commit("workflows/setActiveOwner", selectedId.value);
};

const isSelected = (id: OwnerWFL["personal_information_id"]) =>
  selectedId.value === id;

const handleOpenModal = (owner: OwnerWFL | undefined | null): void => {
  addNewOwner.value = undefined;
  existingData.value =
    fieldData.value?.flatMap(
      ({ personal_information_id }) => personal_information_id
    ) || [];

  closeMenu({
    afterClose: () => {
      if (owner) addNewOwner.value = owner;
      isPersonalInfoModalOpen.value = true;
    }
  });
};

const closeModal = () => {
  isPersonalInfoModalOpen.value = false;
};

const updateSelectedOwner = () => {
  const owner = fieldData.value.find(
    ({ personal_information_id }) =>
      !existingData.value.includes(personal_information_id)
  );

  if (owner) {
    selectedId.value = owner.personal_information_id;
    commit("workflows/setActiveOwner", selectedId.value);
  }
};

const handleDelete = async () => {
  if (!selectedId.value) {
    return;
  }
  const payload = {
    personal: [
      {
        personal_information_id: selectedId.value,
        _delete: true
      }
    ]
  };

  try {
    await dispatch("workflows/updateWorkflowSnapshotFields", payload);
    selectedId.value = fieldData.value?.[0]?.personal_information_id || null;
    commit("workflows/setActiveOwner", selectedId.value);
  } catch (error) {
    return;
  }
};
</script>
